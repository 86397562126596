/**
 * OpenText Variables
 *
 * Thanks to Jason Ibrahim's OT CSS Framework
 * https://gitlab.otxlab.net/applications-team/libraries-and-tools/ot-css-framework
 * src/scss/utilities/initial-variables.scss
 * Note: Some variables have been added and removed to fit in with the Developer Experience site
 */

// Colors
// https://uxd.opentext.net/fundamentals/brand_colors.htm
$ot-navy: rgb(17, 27, 88); // #111a58
$ot-navy-p-1: rgb(13, 20, 66); // #0d1442
$ot-navy-p-2: rgb(9, 14, 44); // #090e2c
$ot-navy-m-1: rgb(65, 73, 121); // #414979

$ot-primary: #0066FF;
$ot-primary-1: #0033CF;
$ot-primary-link: #00008B;
$ot-primary-visited: #D0008D;

$ot-light-blue: rgb(9, 188, 239); // #09bcef
$ot-light-blue-p-1: rgb(7, 141, 179); // #078db3
$ot-light-blue-p-2: rgb(5, 94, 120); // #055d78
$ot-light-blue-p-3: rgb(0, 132, 206); // #0084ce
$ot-light-blue-m-1: rgb(58, 201, 242); // #3ac9f2

$ot-dark-cyan-blue: rgb(16 28 47); // #101C2F

$ot-indigo: rgb(46, 61, 152); // #2e3d98
$ot-indigo-p-1: rgb(35, 46, 114); // #232e72
$ot-indigo-p-2: rgb(23, 31, 76); // #171f4c
$ot-indigo-m-1: rgb(88, 100, 173); // #5864ad
$ot-indigo-2: rgb(37,19,83); // #251353

$ot-plum: rgb(79, 54, 144); // #4f3690
$ot-plum-p-1: rgb(59, 41, 108); // #3b296c
$ot-plum-p-2: rgb(40, 27, 72); // #281b48
$ot-plum-m-1: rgb(114, 94, 166); // #725ea6

$ot-teal: rgb(0, 184, 186); // #00B8BA
$ot-teal-m-1: rgb(51,198,200); // #33C6C8
$ot-teal-p-1: rgb(0, 131, 131); // #008383

$ot-steel: rgb(126, 146, 159); // #7e929f
$ot-steel-p-1: rgb(95, 110, 119); // #5f6e77
$ot-steel-p-2: rgb(63, 73, 80); // #3f4950
$ot-steel-m-1: rgb(152, 168, 178);  // #98A8B2
$ot-steel-m-2: rgb(186, 201, 206); // #bac9ce
$ot-steel-m-3: rgb(203, 211, 217); // #CBD3D9
$ot-light-steel: rgb(207, 216, 220);// #CFD8DC

$ot-cloud: rgb(225, 232, 246); // #e1e8f6
$ot-cloud-two: rgb(225, 225, 225);//#E1E1E1
$ot-cloud-p-1: rgb(169, 174, 185); // #a9aeb9
$ot-cloud-p-2: rgb(113, 116, 123); // #71747b
$ot-cloud-m-1: rgb(231, 237, 248); // #e7edf8
$ot-cloud-m-2: lighten(rgb(231, 237, 248), 20%); // #e7edf8 but lighter!
$ot-cloud-m-3: rgb(243, 246, 251); // #F3F6FB
$ot-cloud-m-4: rgb(51, 51, 51); // #333333

$ot-firebrick: rgb(223, 51, 36); // #df3324
$ot-burnt: rgb(240, 88, 34); // #f05822
$ot-discovery: rgb(238,177,17); // #eeb111
$ot-green: rgb(140, 197, 62); // #8cc53e
$ot-emerald: rgb(8, 166, 26); // #08a61a
$ot-pine: rgb(0,99,83); // #006353
$ot-dark-cyan: rgb(10,130,132); //#0A8284
$ot-cerulean: rgb(0,117,153); // #007599
$ot-lake: rgb(0,132,206); // #0084ce
$ot-lake-p-1: rgb(0, 99, 155); // #00639B
$ot-lake-p-2: rgb(0, 66, 103); // #004267
$ot-lake-m-1: rgb(51, 157, 216); // #339DD8
$ot-lake-m-2: rgb(102, 181, 226); // #66B5E2
$ot-lake-m-3: rgb(153, 206, 235); // #99CEEB
$ot-magenta: rgb(160, 0, 107); // #a0006b
$ot-madder-red: rgb(186, 0, 76); // #ba004c
$ot-raspberry: rgb(224, 0, 81); // #e00051

$ot-text-color-reverse: rgb(255, 255, 255); // #ffffff
$ot-black: rgb(0, 0, 0); // #000000

$ot-grey-darkest: rgb(51,51,51); // #333333
$ot-grey-dark-one: rgb(102,102,102); // #666666
$ot-grey-dark-two: rgb(117,117,117); // #757575
$ot-grey-dark-three: #E8E8E8;
$ot-grey-medium: rgb(153,153,153); // #999999
$ot-grey-light: rgb(204,204,204); // #cccccc
$ot-grey-light-two: #EFEFEF;
$ot-grey-light-three: #DFDFDF;
$ot-grey-lightest: rgb(249,249,249); // #f9f9f9

$ot-background-darkerblue: #090E2C;
$ot-background-blue: #111B58;
$ot-menu-highlight: #B2BEC5;
$ot-menu-picked: #98A8B2;

$ot-development: rgb(17, 88, 27);
$ot-staging: rgb(203, 108, 17);
$ot-border-color: rgba(0,0,0,0.1);
$ot-background-grey: #CBD3D9;
$ot-second-level-grey: #EDF0F2;

$non-ot-common-light-grey: #DCDCDC;
$non-ot-common-grey: #C4C4C4;

/**
 * Text Styles - ANY PRODUCT - LINKS
 */
$ot-paragraph-link-colour: #2f4ed1;

// Typography
// https://uxd.opentext.net/fundamentals/text_styles.htm

$ot-font-family: "Inter var";
$family-inter-var: $ot-font-family, BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$ot-code-family: "Inconsolata";
$family-inconsolata: $ot-code-family, monospace;
/**
 * Developer Experience Variables
 *
 * Custom variables that can be used in the Developer Experience site.
 */

$call-to-action-bg-color: #F2F9FF;

$info: lighten(#0072aa, 10);

$panel-primary: #111634;
$panel-gradient-start: #0D112C;
$panel-gradient-mid: #111634;
$panel-gradient-end: #0B4E6D;

/**
 * Responsive screen widths
 */
$responsive-width-1920: 1466px;
$responsive-width-1440: 1344px;
$responsive-width-1216: 1152px;
$responsive-width-1024: 976px;

$responsive-width-1920-updt: 1471px;
$responsive-width-1440-updt: 1296px;
$responsive-width-1216-updt: 1152px;
$responsive-width-1024-updt: 960px;

$responsive-margin-desktop: 106px;
$responsive-margin-mobile: 32px;
$responsive-margin-mobile-small: 17px;
$responsive-margin-header-mobile: 24px;

$hero-negative-margin-top: -128px;
$hero-negative-margin-top-adjustment: 170px;

$footer-min-height: 222px;

$header-width-1920: 1500px;
$header-width-1216-1439: 1180px;

/**
 * Z-index values
 *
 * Some values in this map are also maintained in the global constant STACKING_CONTEXTS.
 * These values need to be kept in sync.
 */

$z-index-components: (
  anchor-scroll : 3,
  breadcrumb: 20 // The breadcrumb is sticky so should obscure all page content including overlays like the Prism JS copy icon
);

$page-margins: (
  1920: 224px,
  1440: 32px,
  1216: 32px,
  1024: 32px,
  769: 32px,
  375: 16px
);

$page-margins-develop: (
  1920: 32px,
  1440: 32px,
  1216: 32px,
  1024: 32px,
  769: 32px,
  375: 16px
);

$page-columns: (
  1920: 12,
  1440: 12,
  1216: 12,
  1024: 12,
  769: 6,
  375: 3
);

$columns-gap: (
  1920: 24px,
  1440: 16px,
  1216: 16px,
  1024: 24px,
  769: 24px,
  375: 8px
);

$rows-gap: (
  1920: 40px,
  1440: 40px,
  1216: 40px,
  1024: 40px,
  769: 40px,
  375: 40px,
);

$header-height: (
  1920: 74px,
  1440: 74px,
  1216: 74px,
  1024: 68px,
  769: 68px,
  375: 68px
);

$footer-height: (
  1920: 102px,
  1440: 102px,
  1216: 102px,
  1024: 115px,
  769: 171px,
  375: 197px
);

$menu-item-margin: 16px;
$menu-separator-padding: 8px;
$menu-separator-margin: 12px;

$menu-item-width: (
  learn: 50px,
  apis: 40px,
  products-services: 75px,
  resources: 91px,
  plans: 47px,
  community: 96px,
  separator: 1px,
  console: 69px,
  welcome: 77px
);

$scroll-bar-height: 8px;
$scroll-bar-width: 8px;
$scroll-bar-height: 8px;
$scroll-bar-border-radius: 4px;
$scroll-bar-bg-color: $ot-grey-medium;

