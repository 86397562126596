@import "../../variables";

@media screen and (max-width: 768px) {
  #ot2-header-wrapper {
    .ot2-dx-header--content {
      padding-bottom: 10px;
      height: 68px;
      &.not-mobile {
        display: none;
      }
    }

    .mobile-header {
      .mobile-header--menu-button {
        .navbar-burger {
          width: 24px;
          height: 24px;
        }
  
        margin-left: -24px;
      }
      .mobile-header--branding-wrapper {
        width: 176px;
      }
  
      .mobile-header--controls {
        .mobile-search-icon {
          width: 24px;
          height: 24px;
        }
  
        .mobile-search--container {
          .mobile-search--position {
            .ot2-dx-header-search--wrapper {
              width: 300px;
            }
          }
        }
      }
    }
  }
  .mobile-header--branding.searching {
    display: none;
  }
  
  .ot2-mobile-menu--container {
    #ot2-mobile-menu {
      width: 375px;
    }
  }
}
