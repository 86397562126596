@import "../../variables";
@import "../../mixins";

$hero-title-font-size-large: 48px;
$hero-title-font-size-small: 36px;
$hero-title-line-height: 49px;
$hero-sub-title-font-size-large: 24px;
$hero-sub-title-font-size-small: 22px;
$hero-sub-title-line-height: 32px;
$hero-small-height: 275px;
$hero-medium-height-large-screen: 200px;
$hero-medium-height-large-screen: 200px;
$hero-medium-height-medium-screen: 215px;
$hero-medium-height-small-screen: 227px;
$hero-large-height: 550px;
$hero-title-font-size: 36px;
$hero-title-line-height: 32px;


.app-root-grid {
  .app-page {

    nav {
      ul[role=menubar] {
        width: unset;
      }
    }

    &.app-page--type-content {
      .app-page__section {
        &.app-page__section--size-small,
        &.app-page__section--size-large {
          margin-top: 0;
          margin-bottom: 58px;
        }
      }
      .app-page__section:not(:last-child) {
        &.app-page__section--has-divider {
          border-bottom: 1px solid $ot-grey-light;
          padding-bottom: 58px;
        }
      }
    }

    &.app-page--type-marketing {

      .app-page__side-nav {
        background: white;
        padding-top: 10px;
      }

      .app-page__content {
        .app-page__content__positive-margin {
          padding-top: 70px;
        }
      }

      .app-page__section {
        &.app-page__section--size-small {
          margin-top: 0;
          margin-bottom: 87px;
        }

        &.app-page__section--size-large {
          margin-top: 0;
          margin-bottom: 116px;
        }
      }

      .app-page__section:not(:last-child) {
        &.app-page__section--size-small {
          &.app-page__section--has-divider {
            border-bottom: 1px solid $ot-grey-light;
            padding-bottom: 87px;
          }
        }
        &.app-page__section--size-large {
          &.app-page__section--has-divider {
            border-bottom: 1px solid $ot-grey-light;
            padding-bottom: 116px;
          }
        }
      }
    }

    .app-page__negative-margin {
        &.app-page__negative-margin--error-padding-top {
          padding-top: 200px;
        }
    }

    .app-page__side-nav {
      border-right: $ot-indigo-p-1 2px solid;
      padding-top: 10px;

      &.app-page__side-nav--no-border {
        border: none;
      }
    }

    .app-page__content {
      margin-top: 37px;
      margin-bottom: 37px;
    }

    .app-page__section {
      .app-page__section-title {
        color: $ot-grey-darkest;
        margin-bottom: 15px;
        margin-top: 15px;

        &.app-page__section-title--size-small {
          font-size: 28px;
          line-height: 34px;
        }

        &.app-page__section-title--size-medium {
          font-size: 32px;
          line-height: 40px;
        }

        &.app-page__section-title--font-weight-normal {
          font-weight: 400;
        }

        &.app-page__section-title--font-weight-heavy {
          font-weight: 700;
        }

        &.app-page__section-title--text-align-left {
          text-align: left;
        }

        &.app-page__section-title--text-align-center {
          text-align: center;
        }

        &.app-page__section-title--text-align-right {
          text-align: right;
        }
      }

      .app-page__section-sub-title {
        font-size: 16px;
        color: $ot-black;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 15px;

        &.app-page__section-sub-title--text-align-left {
          text-align: left;
        }

        &.app-page__section-sub-title--text-align-center {
          text-align: center;
        }

        &.app-page__section-sub-title--text-align-right {
          text-align: right;
        }

        &.app-page__section-sub-title--width-medium {
          width: 787px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .app-page__hero {
      &.app-page__hero--size-small {
        height: $hero-small-height;
        margin-top: -#{map-get($header-height, 1920)};

        .app-page__hero-background {
          background-color: $ot-indigo;

          .app-page__hero-content {
            padding-top: map-get($header-height, 1920);
          }
        }
      }

      &.app-page__hero--size-medium {
        height: $hero-medium-height-large-screen;

        .app-page__hero-background {
          background-color: $ot-indigo;
        }
      }

      &.app-page__hero--size-large {
        height: $hero-large-height;
        margin-top: -#{map-get($header-height, 1920)};

        .app-page__hero-background {
          background-color: $ot-navy-p-1;

          .app-page__hero-content {
            padding-top: map-get($header-height, 1920);
          }
        }
      }

      .app-page__hero-background {
        height: 100%;

        .app-page__hero-content {
          display: flex;
          height: 100%;

          .app-page__hero-title-search {
            display: flex;
            flex: 0 0 40%;
            height: 100%;
            flex-direction: column;
            justify-content: space-evenly;

            color: $ot-text-color-reverse;
            font-family: $family-inter-var;

            .app-page__hero-title-container {
              display: flex;
              column-gap: 20px;

              .app-page__hero-title {
                line-height: $hero-title-line-height;

                &.app-page__hero-title--size-small {
                  font-size: $hero-title-font-size-small;
                }

                &.app-page__hero-title--size-large {
                  font-size: $hero-title-font-size-large;
                }
              }

              .app-page__hero-title-tagline {
                font-size: 30px;
                color: $ot-text-color-reverse;
              }
            }

            .app-page__hero-sub-title {
              font-size: $hero-sub-title-font-size-large;
              line-height: $hero-sub-title-line-height;
            }

            .app-page__hero-search-container {
              width: 80%;
            }
          }
        }
      }
    }
    .app-page__title {
      font-size: $hero-title-font-size;
      line-height: $hero-title-line-height;
      font-weight: 700;
      color: $ot-grey-darkest;
      margin-top: 24px;
    }
  }
}

@mixin appPageMixin($pageWidth) {
  .app-root-grid {
    .app-page {
      &.app-page--hero-no-overlap {
        @include content-height($pageWidth);
      }

      &.app-page--hero-overlap {
        @include content-height-hero-overlap($pageWidth);
      }

      &.app-page--type-detail {
        .app-page__content {
          margin-top: unset;
          margin-bottom: unset;

          @if $pageWidth > 1023 {
            margin-left: -#{map-get($page-margins, $pageWidth)};
          }

          @if $pageWidth == 1920 {
            margin-right: calc(-#{map-get($page-margins, $pageWidth)} + #{map-get($page-margins, 1440)});
          }

          .app-page__side-nav {
            margin: unset;
          }
        }
      }

      .app-page__negative-margin {
        @include content-margins-negative($pageWidth);

        &.app-page__negative-margin--has-background {
          @include content-height($pageWidth);
        }
      }

      .app-page__positive-margin {
        height: 100%;
        @include content-margins-positive($pageWidth);
      }

      .app-page__content {
        .app-page__content__negative-margin {
          @include content-margins-negative($pageWidth);

          .app-page__content__positive-margin {
            @include content-margins-positive($pageWidth);
          }
        }
      }

      .app-page__section {
        .app-page__section__negative-margin {
          @include content-margins-negative($pageWidth);

          .app-page__section__positive-margin {
            @include content-margins-positive($pageWidth);
          }
        }
      }

      &.app-page--type-develop {
        .app-page__negative-margin {
          @include content-margins-negative-develop($pageWidth);
        }

        .app-page__positive-margin {
          height: 100%;
          @include content-margins-positive-develop($pageWidth);
        }

        .app-page__content {
          .app-page__content__negative-margin {
            @include content-margins-negative-develop($pageWidth);

            .app-page__content__positive-margin {
              @include content-margins-positive-develop($pageWidth);
            }
          }
        }

        .app-page__section {
          .app-page__section__negative-margin {
            @include content-margins-negative-develop($pageWidth);

            .app-page__section__positive-margin {
              @include content-margins-positive-develop($pageWidth);
            }
          }
        }
      }

      .app-page__side-nav {
        @if $pageWidth < 1920 {
          margin-left: -#{map-get($page-margins, $pageWidth)};
        }
      }
    }

    & {
      /*
       * overrides for header, hero and footer until they can all be changed to use the 12 column layout
       * start
       */
      .header-content--container {
        width: unset;

        .ot2-dx-header-submenu--wrapper {
          .ot2-dx-header-submenu.level.header-content--container {
            margin: 0 calc(#{map-get($page-margins-develop, $pageWidth)} - #{$menu-item-margin});
          }
        }
      }

      #ot2-header-wrapper {
        @include content-margins-negative($pageWidth);

        #ot2-header-content--wrapper.header-content--container {
          @include content-margins-positive-develop($pageWidth);
          padding-left: unset;
          padding-right: unset;
          display: flex;
          flex-direction: column;

          @if $pageWidth > 1023 {
            width: 100%;
          }

          div.ot2-dx-header--content {
            padding: 0;

            div.mobile-header--menu-button {
              margin-left: unset;
            }
          }
        }
      }

      #ot2-dx-footer--content.header-content--container {
        width: unset;
        @include content-margins-positive-develop($pageWidth);
      }

      div.level.not-mobile {
        #ot2-header-navigation.level-left {
          a.ot2-header-nav-item:first-child {
            margin-left: 0 !important;
          }
        }
      }

      div#ot-breadcrumb-wrapper {
        width: unset;

        @include content-margins-negative($pageWidth);

        div.ot-breadcrumb-container {
          width: unset;

          @include content-margins-positive($pageWidth);
          padding-left: unset;

          div.ot-breadcrumb:first-child {
            margin-left: 0;
          }
        }
      }

      .ot2-dx-hero-background {
        @include content-margins-negative($pageWidth);

        div.ot2-dx-hero {
          @include content-margins-positive($pageWidth);

          @if $pageWidth == 375 {
            div.search-container {
              @include content-margins-negative($pageWidth);

              p.control {
                @include content-margins-positive($pageWidth);
              }
            }
          }
        }
      }

      div.side-nav-mobile-toggle-container {
        margin-bottom: unset;
      }

      div#ot-dx-left-menu-content {
        div.top-level-container {
          a.top-level-link-resource-tiles {
            margin-left: unset !important;
          }
        }
      }

      #ot-dx-left-menu-content {
        .top-level-container {
          .second-level-container {
            border-left-width: 34px !important;
          }
        }
      }

      div#ot2-dx-footer {
        @include content-margins-negative($pageWidth);
      }

      #ot2-dx-success-stories-wrapper {
        #ot2-dx-success-stories-background--image {
          @include content-margins-negative($pageWidth);
        }
      }

      #ot2-dx-tutorials-wrapper {
        #ot2-dx-tutorials-background--image {
          @include content-margins-negative($pageWidth);
        }
      }

      #ot2-dx-white-papers-wrapper {
        #ot2-dx-white-papers-background--image {
          @include content-margins-negative($pageWidth);
        }
      }

      #ot2-dx-learn-overview-background--image {
        @include content-margins-negative($pageWidth);
      }


      /*
       * overrides for header, hero and footer
       * end
       */
    }
  }
}

@media screen and (min-width: 1920px) {
  @include appPageMixin(1920);
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  @include appPageMixin(1440);
}

@media screen and (min-width: 1216px) and (max-width: 1439px) {
  @include appPageMixin(1216);

  .app-root-grid {
    .app-page {
      .app-page__hero {
        .app-page__hero-background {
          .app-page__hero-content {
            .app-page__hero-title-search {
              flex: 0 0 50%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  @include appPageMixin(1024);

  .app-root-grid {
    .app-page {
      .app-page__hero {

        &.app-page__hero--size-small {
          margin-top: -#{map-get($header-height, 1024)};

          .app-page__hero-background {
            .app-page__hero-content {
              padding-top: map-get($header-height, 1024);
            }
          }
        }

        &.app-page__hero--size-medium {
          height: $hero-medium-height-medium-screen;
        }

        &.app-page__hero--size-large {
          margin-top: -#{map-get($header-height, 1024)};

          .app-page__hero-background {
            .app-page__hero-content {
              padding-top: map-get($header-height, 1024);
            }
          }
        }

        .app-page__hero-background {
          .app-page__hero-content {
            .app-page__hero-title-search {
              flex: 0 0 50%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  @include appPageMixin(769);

  .app-root-grid {
    .app-page {
      .app-page__section {
        .app-page__section-sub-title {
          &.app-page__section-sub-title--width-medium {
            width: unset;
          }
        }
      }

      .app-page__hero {
        &.app-page__hero--size-small {
          margin-top: -#{map-get($header-height, 769)};

          .app-page__hero-background {
            .app-page__hero-content {
              padding-top: map-get($header-height, 769);
            }
          }
        }

        &.app-page__hero--size-medium {
          height: $hero-medium-height-medium-screen;
        }

        &.app-page__hero--size-large {
          margin-top: -#{map-get($header-height, 769)};

          .app-page__hero-background {
            .app-page__hero-content {
              padding-top: map-get($header-height, 769);
            }
          }
        }

        .app-page__hero-background {
          .app-page__hero-content {
            .app-page__hero-title-search {
              flex: 0 0 50%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @include appPageMixin(375);

  .app-root-grid {
    .app-page {
      .app-page__section {
        .app-page__section-sub-title {
          &.app-page__section-sub-title--width-medium {
            width: unset;
          }
        }
      }

      .app-page__negative-margin {
        &.app-page__negative-margin--error-padding-top {
          padding-top: 50px;
        }
      }

      .app-page__hero {

        &.app-page__hero--size-small {
          margin-top: -#{map-get($header-height, 375)};

          .app-page__hero-background {
            .app-page__hero-content {
              padding-top: map-get($header-height, 375);
            }
          }
        }

        &.app-page__hero--size-medium {
          height: $hero-medium-height-small-screen;
        }

        &.app-page__hero--size-large {
          margin-top: -#{map-get($header-height, 375)};

          .app-page__hero-background {
            .app-page__hero-content {
              padding-top: map-get($header-height, 375);
            }
          }
        }

        .app-page__hero-background {
          .app-page__hero-content {
            .app-page__hero-title-search {
              flex: 0 0 100%;

              .app-page__hero-title-container {
                .app-page__hero-title {
                  font-size: $hero-title-font-size-small;
                }
              }

              .app-page__hero-sub-title {
                font-size: $hero-sub-title-font-size-small;
              }
            }
          }
        }
      }
    }
  }
}
