@import "../../../variables";

nav.ot2-header-bar-dropdown-menu {
  background-color: $ot-text-color-reverse;
  box-shadow: 0 0 5px #000000b3;
  position: absolute;
  min-width: 192px;
  top: 74px;
  .ot2-dx-header-submenu {
    
    .console-menu-items {
      display: flex;
      padding: 4px 16px 0px 16px;
      a {
        display: block;
        height: 31px;
        color: #333333;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;
        padding-right: 10px;
      }
    }
    span:hover {
      background-color: #B2BEC5
    }
    span:active {
      background-color: #B2BEC5
    }
    .ot2-dx-header-submenu-item-descriptor {
      display: flex;
      align-items: center;
      font-size: 10px;
      height: 26px;
      color: black;
      text-align: center;
    }
  }
}
