@import "../../variables";

.spinner-container {
  border: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  top: 0;
  left: 0;
  margin: auto;

  &.adjust-for-negative-margin {
    margin-top: $hero-negative-margin-top-adjustment;
    height: calc(100% - 24px);

    &.adjust-resources-documentation {
      height: calc(100% - 100px);
    }

    .spinner-outer-border {
      top: calc(50% - #{$hero-negative-margin-top-adjustment});
    }
   }
}

.spinner-outer-border {
  top: 40%;
  left: calc(50% - 46px);
  position: absolute;
  &.adjust-onboarding-pages {
    top: 60%;
    left: 60%;
  }
}

.spinner {
   width: 92px;
   height: 92px;
   background-image: url('../../../assets/gifs/spinner.gif');
   background-repeat: no-repeat;
   background-size: cover; 
}

@media screen and (max-width: 769px) {
  .spinner-container {
    .spinner-outer-border {
      top: 18%;
      left: 42%;
    }
  }
}
