@import './variables.scss';

$fade-in-duration: 2s;
$fade-out-duration: 2s;

@mixin sticky-left-menu() {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: calc(100vh - #{$footer-min-height});
}

@mixin sticky-right-menu() {
  overflow-y: auto;
  position: sticky;
  top: 150px;
  height: calc(100vh - 150px);
  
  &::-webkit-scrollbar {
    width: $scroll-bar-width;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $scroll-bar-border-radius;
    background-color: $scroll-bar-bg-color;
  }
}

@mixin sticky-breadcrumb() {
  position: sticky;
  left: 0px;
  z-index: map-get($z-index-components, breadcrumb);
  background: $ot-text-color-reverse;
  box-shadow: -2px -7px 0px 7px rgb(255 255 255);
}

@mixin sign-in-base() {
  display: block;
  width: 140px;
  height: 41px;
  border-radius: 20px;
  font-weight: 400;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
}

@mixin content-height($breakpoint, $additional: 0) {
  min-height: calc(100vh - #{map-get($header-height, $breakpoint)} - #{map-get($footer-height, $breakpoint)} - #{$additional}px);
}

@mixin content-height-hero-overlap($breakpoint) {
  min-height: calc(100vh - #{map-get($footer-height, $breakpoint)}) !important;
}

@mixin content-margins-positive($breakpoint) {
  margin: 0 #{map-get($page-margins, $breakpoint)};
}

@mixin content-margins-negative($breakpoint) {
  margin: 0 -#{map-get($page-margins, $breakpoint)};
}

@mixin content-margins-positive-develop($breakpoint) {
  margin: 0 #{map-get($page-margins-develop, $breakpoint)};
}

@mixin content-margins-negative-develop($breakpoint) {
  margin: 0 -#{map-get($page-margins, $breakpoint)};
}

@mixin page-column-template($breakpoint) {
  grid-template-columns:
    map-get($page-margins, $breakpoint) 1fr map-get($page-margins, $breakpoint);
}

@mixin animate-fade-in($fade-in: $fade-in-duration) {
  animation: fadeIn $fade-in;
  -webkit-animation: fadeIn linear $fade-in;
  -moz-animation: fadeIn linear $fade-in;
  -o-animation: fadeIn linear $fade-in;
  -ms-animation: fadeIn linear $fade-in;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@mixin animate-fade-out($fade-out: $fade-out-duration) {
  animation: fadeOut $fade-out;
  -webkit-animation: fadeOut linear $fade-out;
  -moz-animation: fadeOut linear $fade-out;
  -o-animation: fadeOut linear $fade-out;
  -ms-animation: fadeOut linear $fade-out;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-moz-keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-webkit-keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-o-keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-ms-keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
