@import "../../variables";

@media screen and (min-width: 1216px) and (max-width: 1439px) {
  .ot2-header-wrapper--no-subnav {
    height: 80px !important;
  }

  .header-content--container {
    width: $header-width-1216-1439;
    .ot2-dx-header-search--wrapper {
      height: 36px;
    }
  }
}

@media screen and (min-width: 1396px) and (max-width: 1439px) {
  #ot2-header-wrapper {
    .ot2-dx-header--content {
      .branding-container {
        .desktop-menu {
          margin-left: 40px;
        }
      }
    }
  }

  .header-content--container {
    .ot2-dx-header-search--wrapper {
      width: 324px;
    }
  }
}

@media screen and (min-width: 1216px) and (max-width: 1395px) {
  #ot2-header-wrapper {
    .ot2-dx-header--content {
      .branding-container {
        .desktop-menu {
          margin-left: 40px;
        }
      }
    }
  }

  .header-content--container {
    .ot2-dx-header-search--wrapper {
      width: 240px;
    }
  }
}
