@import "../../variables";

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .mobile-header--branding.searching {
    display: none;
  }

  #ot2-header-wrapper {
    .ot2-dx-header--content {
      height: 68px;
      &.not-mobile {
        display: none;
      }
    }
    .mobile-header--menu-button {
      .navbar-burger {
        width: 24px;
        height: 24px;
      }
      margin-left: -8px;
    }

    .mobile-header {
      .mobile-header--controls {
        .mobile-search-icon {
          width: 24px;
          height: 24px;
        }
      }
      .mobile-search--container {
        .mobile-search--position {
          .ot2-dx-header-search--wrapper {
            width: 649px;
          }
        }
      }
    }

    .header-content--container {
      .ot2-dx-header--content {
        padding-bottom: 10px;
      }
    }
  }

  .ot2-mobile-menu--container {
    #ot2-mobile-menu {
      width: 494px;
    }
  }
}
