@import "../../variables";

// ===COLOR===
$ot2-header-background-color: rgba($ot-dark-cyan-blue, 0.7);
$ot2-header-background-color-solid: rgba($ot-dark-cyan-blue, 1);

// ===SIZE===
$ot2-header-height: 74px;
/* OT2 Header */

#ot2-header-navigation,
#ot2-console-menu-item {
  height: 74px;
}

.ot2-header-wrapper--color {
  background: $ot2-header-background-color;

  &.ot2-header-is-solid {
    background: $ot2-header-background-color-solid;
  }
}

#ot2-header-wrapper {
  color: $ot-text-color-reverse;
  height: 74px;
  position: sticky;
  top: 0;

  .ot2-dx-header--content {
    margin: 0;
    padding: 0 0 0 16px;
    height: 74px;
    display: flex;
    justify-content: space-between;
  }

  .branding-container {
    display: flex;
    justify-content: space-between;

    #ot2-dx-header--branding {
      display: flex;
      margin-top: 10px;

      img {
        &.branding-logo {
          max-width: 267px;
        }
      }
    }

    .desktop-menu {
      margin-left: 59px;

      .ot2-header-nav {
        display: flex
      }
    }
  }

  .navbar-burger {
    color: $ot-text-color-reverse;
    background: url("../../../assets/img/icon-hamburger24.svg") no-repeat;
    height: 24px;
    width: 24px;
    margin-left: 0;
  }

  .parent {
    background: rgba($ot-navy, 0.0);
  }

  .ot2-dx-header-submenu-item {
    background: rgba($ot-navy-p-2, 0);
  }

  a.ot2-header-button-rounded {
    border-radius: 16px;
    height: 32px;
    margin-top: 19px;
  }

  a.ot2-header-button-trans:hover {
    background: rgba($ot-black, 0.1);
  }

  a.ot2-header-button-solid {
    background: $ot-indigo;
    height: 32px;
    color: $ot-text-color-reverse !important;
    font-size: 14px;
  }

  a.ot2-header-button-solid:hover {
    background: $ot-light-blue-p-3;
  }

  a.ot2-header-button-solid:active {
    background: $ot-lake-p-1;
  }

  nav.ot2-avatar-menu {
    background-color: $ot-text-color-reverse;
    color: $ot-grey-darkest;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    width: 224px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      cursor: pointer;

      li {
        margin: 0;
        width: 100%;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        align-items: center;
        color: $ot-grey-darkest;
        display: block;

        a {
          height: 36px;
          padding: 5px 0px 2px 10px;
          display: block;
          color: $ot-grey-darkest;
          margin-top: 2px;

          &:visited {
            background-color: $non-ot-common-grey;
            color: $ot-grey-darkest;
          }
        }

        &:hover,
        &.is-hovered {
          background-color: $non-ot-common-grey;
          color: $ot-grey-darkest;
        }

        &:active {
          border-top: 1px solid $ot-text-color-reverse;
          border-bottom: 1px solid $ot-text-color-reverse;
          background-color: $ot-menu-picked;
          color: $ot-grey-darkest;
        }

        &:focus {
          outline: 1px solid $ot-indigo;
          outline-offset: 0;
        }

        .uxd-menu-icon {
          height: 16px;
          width: 16px;
          padding: 0;
          margin: 0 8px 0 0;
        }

        .dropdown-menu {
          padding: 3px 16px 3px 16px
        }

        &.dropdown-menu-border {
          border-bottom: 1px solid $non-ot-common-light-grey;
        }

        &.item-focus {
          height: 36px;
          padding: 5px 0px 2px 10px;

          &:focus {
            outline: 1px solid $ot-indigo;
            outline-offset: 0;
          }
        }
      }

      ul.ot2-avatar-signin-item {
        border-top: 1px solid $non-ot-common-light-grey;
      }
    }
  }

  .ot2-header-bar-avatar {
    .ot2-header-bar-signup-icon {
      height: 42px;
      width: 42px;
      cursor: pointer;
      display: flex;
      z-index: 2;
      background: url(../../../assets/img/header/avatar.svg) no-repeat;
      background-size: 42px 42px;

      &.ot2-header-bar-avatar-icon-user-menu {
        font-size: 20px;
      }
    }

    .ot2-header-bar-signed-in-icon {
      height: 46px;
      width: 46px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      border-radius: 500px;
      background-size: 42px 42px;

      &.ot2-header-bar-avatar-icon-user-menu {
        font-size: 20px;

        &.ot2-header-bar-avatar-menu-background {
          background-color: #B33389;
          color: $ot-text-color-reverse;
        }
      }
    }

    &.ot2-header-bar-avatar-dropdown {
      position: relative;

      .ot2-header-bar-avatar-dropdown-menu {
        display: none;
        position: absolute;
        right: 0;
        z-index: 1;
        margin-top: 6px;

        &.ot2-header-bar-avatar-dropdown-menu--is-active {
          display: block;
        }
      }
    }
  }

  a.signin {
    padding: 0 24px;
    font-size: 18px;
    color: $ot-text-color-reverse;

    &:hover {
      color: $ot-lake;
      background: none;
    }

    &:active,
    &:focus-within {
      color: $ot-lake-p-1;
      background: none;
    }
  }

  a.signup {
    margin-left: 8px;
    padding: 0 23px;
    color: $ot-indigo-p-1;
    border: 1px solid $ot-indigo;
    font-size: 14px;
    line-height: 24px;
    width: 96px;
  }

  .mobile-header {
    display: flex;

    .mobile-header--menu-button {
      flex-grow: 0;
      height: 24px;
    }

    .mobile-header--controls {
      flex-grow: 0;
      column-gap: 10px;

      .mobile-search--container {

        .mobile-search--position {
          position: relative;

          .ot2-dx-header-search--wrapper {
            height: 36px;
            font-size: 16px;
          }
        }
      }

      .mobile-search-icon {
        display: block;
        height: 42px;
        width: 42px;
        background: url("../../../assets/img/header/mobile_header_search42.svg") no-repeat center center;
      }
    }
  }

  @media screen and (min-width: 1216px) {
    .is-mobile-only {
      display: none;
    }

    #ot2-header-navigation {
      .ot2-nav-item-divider--container {
        padding-top: 4px;
        margin-right: 17px;
        margin-left: -17px;
      }

      a.ot2-header-nav-item {
        margin-right: 34px;
      }

      .ot2-header-nav-item {
        font-size: 16px;
        display: flex;
        align-items: center;
        border-bottom: 4px solid transparent;
        position: relative;
      }

      a.ot2-header-nav-item.ot2-header-nav-item.selected {
        font-weight: bold;
      }

      a.ot2-header-nav-item:hover::after,
      .ot2-header-nav-item--subnav-hover::after,
      a.ot2-header-nav-item.ot2-header-nav-item.selected::after {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: -4px;
        height: 8px;
        width: 100%;
        border-radius: 8px 8px 0 0;
        background-color: $ot-primary;
      }

      .search-icon {
        margin-left: 32px;
        margin-right: 12px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  a.dropdown-item {
    color: black;

    &:hover {
      background: $ot-menu-highlight;
    }

  }
}

.ot2-header-nav-item,
.ot2-header-nav-item:hover,
.ot2-header-nav-item:active {
  color: $ot-text-color-reverse;
}

.ot2-dx-header--gradient {
  background: #1a2146;
  background: -moz-linear-gradient(left, #1a2146 0%, #1d254d 14%, #212a57 26%, #273165 37%, #253668 50%, #214272 64%, #1c5984 75%, #0b85a8 88%, #078daf 100%);
  background: -webkit-linear-gradient(left, #1a2146 0%, #1d254d 14%, #212a57 26%, #273165 37%, #253668 50%, #214272 64%, #1c5984 75%, #0b85a8 88%, #078daf 100%);
  background: linear-gradient(to right, #1a2146 0%, #1d254d 14%, #212a57 26%, #273165 37%, #253668 50%, #214272 64%, #1c5984 75%, #0b85a8 88%, #078daf 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a2146', endColorstr='#078daf', GradientType=1);
}

.ot2-dx-header--transparent {
  background: transparent;
}

/* Branding */

/* Header Navigation */
#ot2-page-title {
  margin: 0 0 0 auto;
}

.ot2-circle-image {
  background: url("../../../assets/img/header/li_header_search42_mo.svg") no-repeat center center;
  min-width: 42px;
  min-height: 42px;
}

.search-icon-active-notext {
  background: url("../../../assets/img/header/li_header_search42_activated.svg") no-repeat center center;
}

.search-icon-active-notext:hover {
  background: url("../../../assets/img/header/li_header_search42_activated.svg") no-repeat center center !important;
}

.search-icon-active {
  background: url("../../../assets/img/header/li_header_search42_on.svg") no-repeat center center;
}

.search-icon-active:hover {
  background: url("../../../assets/img/header/li_header_search42_on.svg") no-repeat center center !important;
}

.icon-caret-right {
  height: 36px;
  width: 34px;
  cursor: pointer;
  border-right: 1px solid #E0E0E0;
  border-radius: 3px 1px 1px 3px;
  position: absolute;
  z-index: 2;
  background: white;

  img {
    margin: 8px;
  }
}

.ot2-header-profile-container {
  max-height: inherit;
  padding-right: 24px;
  width: 42px;
  height: 42px;
  background-color: $ot-indigo;
  border-radius: 21px;
  outline: none;
}

.ot2-header-profile-container:hover {
  background-color: $ot-lake;
}

.ot2-header-profile-container:active,
.ot2-header-profile-container:focus {
  background-color: $ot-lake-p-1;
}

.ot2-header-profile {
  flex-shrink: 0;
  margin-top: 2px;
  margin-left: 2px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 19px;
  width: 38px;
  height: 38px;
  background-image: url("../../../assets/img/avatars/robot-1_OT_32.svg");

  &:hover {
    background-image: url("../../../assets/img/avatars/robot-1_OT_32mo.svg");
  }

}

.ot2-dx-header-search--wrapper {
  height: 36px;
  font-size: 18px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0;
  background: rgba($ot-text-color-reverse, 0.1);
  position: relative;


  .ot2-dx-header--search {
    height: 36px;
    border: none;
    color: $ot-black;
    padding-right: 64px;
    padding-left: 42px;
  }

  .ot2-dx-header--search::placeholder,
  .ot2-dx-header--search::-webkit-input-placeholder {
    color: $ot-grey-dark-two;
  }

  .ot2-dx-header--search:-ms-input-placeholder {
    color: $ot-grey-dark-two;
  }

  .ot2-dx-header-search--close-image-wrapper {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 62px;
    top: 0px;
  }

  .ot2-dx-header-search--close-image {
    display: block;
    height: 14px;
    width: 17px;
    background: url("../../../assets/img/clear.svg") no-repeat;
    background-size: contain;
    margin-right: 4px;
  }

  .ot2-dx-header-search--image {
    display: block;
    height: 32px;
    width: 32px;
    background: url("../../../assets/img/header/action_search32.svg") no-repeat;
    background-size: contain;
    margin-top: 1px;
  }

}

.is-active {
  background: white;
}

hr {
  margin: 0;
}

.burger {
  align-items: flex-start;
}

.burger .navbar-burger {
  margin-left: 0;
  margin-right: 0;
}

.ot2-dx-header--user-controls {
  display: flex;
  align-items: center;

  .level-item {
    margin-left: 0;
    margin-right: 0;
    z-index: 1;
  }

  .ot2-header--icons-wrapper {
    display: flex;
    margin-right: 19px;
  }

  .icon-caret-right {
    height: 36px;
  }
}

@media screen and (max-width: 1215px) {
  #ot2-header-wrapper {
    height: 68px;
  }

  #ot2-dx-header--branding {
    position: relative;
    top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      width: auto;
    }
  }

  .ot2-dx-header-search--close-image-wrapper {

    .ot2-dx-header-search--image {
      height: 30px;
      width: 30px;
    }
  }

  .ot2-mobile-menu--container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;

    #ot2-mobile-menu--fullscreen-shadow {
      background: rgba($ot-black, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
    }

    #ot2-mobile-menu {
      position: relative;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      width: 500px;
      min-height: 100vh;

      #topHamburgerItem {
        padding: 0;
        margin: 0;
      }

      #topHamburgerItem:focus {
        outline-offset: 2px;
      }

      .ot2-mobile-menu--header {
        display: flex;
        background: #27364C;
        padding: 27px 16px 27px 0;
        height: 68px;
        justify-content: right;


        .ot2-mobile-menu--title {
          flex-grow: 1;
          font-size: 20px;
          width: 100%;

          a {
            padding: 0;
            display: block;

            &:hover {
              background: none;
            }
          }
        }

        .ot2-mobile-menu--close {
          a {
            display: block;
            height: 14px;
            width: 14px;
            padding: 0;
          }

          a:hover {
            background: none;
          }
        }
      }

      a {
        padding: 8px 8px 8px 16px;
      }

      a,
      a:active,
      a:visited {
        color: $ot-text-color-reverse;
      }

      a:hover {
        background: $ot-light-blue-p-3;
      }

      .mobile-menu-parent {
        background: url("../../../assets/img/icon-caret-down-white16.svg") no-repeat right 16px center;
      }

      a.mobile-menu-parent:hover {
        background: $ot-light-blue-p-3 url("../../../assets/img/icon-caret-down-white16.svg") no-repeat right 16px center;
      }

      .mobile-menu-parent-active {
        background: url("../../../assets/img/icon-caret-up-white16.svg") no-repeat right 16px center;
      }

      a.mobile-menu-parent-active:hover {
        background: $ot-light-blue-p-3 url("../../../assets/img/icon-caret-up-white16.svg") no-repeat right 16px center;
      }
    }
  }
}
