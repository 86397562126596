// ====IMAGE====
$ot2-filter-no-results-1440: "../../../assets/img/components/filter/image-search-noresults-1440.svg";
$ot2-filter-no-results-modal-close: "../../../assets/img/components/filter/icon-close20.svg";
$ot2-filter-no-results-modal-close-mo: "../../../assets/img/components/filter/icon-close20mo.svg";

@import "../../variables";

.modal {
  height: 100vh;
  align-items: flex-end;
  white-space: pre-line;

  .delete, .modal-close {
    background-color: transparent;
    width: 30px;
    height: 30px;
    max-width: 30px;
    max-height: 30px;
  }

  .delete::before, .modal-close::before, .delete::after, .modal-close::after {
    background-color: black;
  }

  .tabs {
    padding: 1rem 1rem 0;
    ul {
      border-bottom: 1px $ot-grey-light;
    }
  }

  h2 {
    font-size: 16px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  h2:first-child {
    margin-top: 0;
  }

  .modal-card {
    .modal-card-head {
      padding: 32px 43px 12px 43px;
      border-radius: 0;
      background-color: $ot-text-color-reverse;
      border-bottom: none;
      .modal-card-title {
        color: $ot-steel-p-1;
        font-family: "OTSans", Helvetica, sans-serif;
        font-size: 24px;
        line-height: 32px;
      }
    }
    .modal-card-body {
      padding: 12px 43px 50px 43px;
      font-family: "OTSans", Helvetica, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #000000;

      &.no-margin-bottom {
        margin-bottom: 0;
      }
    }
    .modal-card-foot {
      border-radius: 0;
      background-color: #F4F4F4;
      border-top: none;
      .modal-button {
        min-width: 96px;
        height: 32px;
        display: block;
        font-family: "OTSans", Helvetica, sans-serif;
        border: 1px solid $ot-text-color-reverse;
        font-size: 16px;
        text-align: center;
        border-radius: 25px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.is-primary {
  color: $ot-text-color-reverse;
  background: $ot-indigo-p-1;
}

.is-primary:disabled {
  color: $ot-text-color-reverse;
  background: $ot-grey-medium;
}

.is-secondary {
  color: $ot-indigo-p-1;
  background: $ot-text-color-reverse;
  margin-left: 24px;
  border: 1px solid $ot-indigo-p-1;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 14px;
  width: 14px;
  border: none;
  background: $ot-text-color-reverse url($ot2-filter-no-results-modal-close) no-repeat center center;

  &:hover{
    background: url($ot2-filter-no-results-modal-close-mo) no-repeat center center $ot-text-color-reverse;
  }
}

.modal-card-background {
  background: url($ot2-filter-no-results-1440) no-repeat 43px 24px;
  height: 230px;
}

.modal .modal-card .modal-card-head .modal-card-title.no-results-filter-modal-card-title {
  margin-left: 125px;
  font-size: 24px;
  line-height: 36px;
  color: $ot-grey-darkest;
  position: absolute;
  top: 34px;
}
