@import "../../variables";

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .mobile-header--branding.searching {
    display: none;
  }

  #ot2-header-wrapper {
    .ot2-dx-header--content {
      height: 68px;
      &.not-mobile {
        display: none;
      }
    }

    .is-mobile-only {
      display: inline-flex;
      width: 100%;
    }

    .mobile-header--menu-button {
      .navbar-burger {
        width: 24px;
        height: 24px;
      }
      margin-left: -8px;
    }

    .mobile-header {
      .mobile-header--controls {
        .mobile-search-icon {
          width: 24px;
          height: 24px;
        }
        .mobile-search--container {
          .mobile-search--position {
            .ot2-dx-header-search--wrapper {
              width: 904px;
            }
          }
        }
      }
    }

    .header-content--container {
      .ot2-dx-header--content {
        padding-bottom: 10px;
      }
    }
  }

  .header-content--container {
    width: 100%
  }

  .navbar-burger {
    display: inline-block;
  }

  .mobile-search-icon {
    margin-top: 2px;
  }
}
