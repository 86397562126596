@import "../../variables";

@media screen and (min-width: 1920px) {
  .ot2-header-wrapper--no-subnav {
    height: 80px !important;
  }

  .header-content--container {
    width: $header-width-1920;
    .ot2-dx-header-search--wrapper {
      width: 847px;
    }
  }
}
