@import "../../variables";

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .ot2-header-wrapper--no-subnav {
    height: 80px !important;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1560px) {
  .ot2-dx-header-search--wrapper {
    width: 350px;
  }
}

@media screen and (min-width: 1561px) and (max-width: 1919px) {
  .ot2-dx-header-search--wrapper {
    width: 492px;
  }
}
